import { colors } from '@/styles';

const buttonTheme = {
  button: {
    primary: {
      color: colors.primary.white,
      background: colors.primary.gray,
      borderColor: colors.primary.gray,
      padding: '15px 30px',
      disabled: {
        color: colors.semantic.disabled,
        background: colors.secondary.lightGray1,
        borderColor: colors.secondary.lightGray1,
      },
      danger: {
        color: colors.primary.white,
        background: colors.semantic.negative.dark,
        borderColor: colors.semantic.negative.dark,
      },
    },
    basic: {
      color: colors.primary.gray,
      background: colors.secondary.lightGray2,
      borderColor: colors.secondary.lightGray3,
      padding: '15px 30px',
      disabled: {
        color: colors.semantic.disabled,
        background: colors.secondary.lightGray1,
        borderColor: colors.secondary.lightGray1,
      },
      danger: {
        color: colors.primary.white,
        background: colors.semantic.negative.dark,
        borderColor: colors.semantic.negative.dark,
      },
    },
    secondary: {
      color: colors.primary.gray,
      background: 'transparent',
      borderColor: colors.secondary.lightGray2,
      padding: '15px 30px',
      disabled: {
        color: colors.semantic.disabled,
        background: 'transparent',
        borderColor: colors.secondary.lightGray2,
      },
      danger: {
        color: colors.semantic.negative.dark,
        background: 'transparent',
        borderColor: colors.semantic.negative.dark,
      },
    },
    text: {
      color: colors.primary.gray,
      background: 'transparent',
      borderColor: 'transparent',
      padding: '0',
      disabled: {
        color: colors.semantic.disabled,
        background: 'transparent',
        borderColor: 'transparent',
      },
    },
    cta: {
      color: colors.primary.white,
      background: colors.secondary.blue2,
      borderColor: colors.secondary.blue2,
      padding: '15px 30px',
      alternate: {
        color: colors.primary.white,
        background: colors.secondary.blue3,
        borderColor: colors.secondary.blue3,
      },
      disabled: {
        color: colors.primary.black,
        background: colors.secondary.blue1,
        borderColor: colors.secondary.blue1,
      },
      selected: {
        color: colors.primary.black,
        background: colors.secondary.blue1,
        borderColor: colors.secondary.blue1,
      },
    },
    unstyled: {
      background: 'transparent',
      borderColor: 'transparent',
      padding: '0',
      disabled: {
        color: colors.semantic.disabled,
        background: 'transparent',
        borderColor: 'transparent',
      },
    },
  },
};

export default buttonTheme;
