'use client';
import styled from 'styled-components';

const getPadding = (props: any) => {
  if (props.$small) return '10px 20px';
  if (props.$smaller) return '8px 25px';
  if (props.$inline) return '5px 10px';
  return props.theme.button[props.$variant]?.padding;
};

export const Button = styled.button<{
  $variant: string;
  $nowrap?: boolean;
  $stretch?: boolean;
  $rounded?: boolean;
  $small?: boolean;
  $smaller?: boolean;
  $inline?: boolean;
}>`
  /* display: inline; */
  display: ${(props) => (props.$stretch ? 'flex' : 'inline-flex')};
  width: ${(props) => (props.$stretch ? '100%' : 'auto')};
  min-height: ${(props) => (props.$inline ? '38px' : 'auto')};
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: ${(props) => getPadding(props)};
  /* props.$small ? '10px 20px' : props.$inline ? '5px 10px' : props.theme.button[props.$variant]?.padding}; */
  /* padding: ${(props) => (props.$small ? '10px 20px' : props.$inline ? '5px 10px' : '15px 30px')}; */
  border-radius: ${(props) => (props.$rounded ? '500px' : '5px')};
  border: 1px solid ${(props) => props.theme.button[props.$variant]?.borderColor};
  color: ${(props) => props.theme.button[props.$variant]?.color};
  white-space: ${(props) => (props.$nowrap ? 'nowrap' : 'inherit')};
  background-color: ${(props) => props.theme.button[props.$variant]?.background};
  font-size: 14px;
  cursor: pointer;

  &.alternate {
    border-color: ${(props) => props.theme.button[props.$variant]?.alternate?.borderColor};
    color: ${(props) => props.theme.button[props.$variant]?.alternate?.color};
    background-color: ${(props) => props.theme.button[props.$variant]?.alternate?.background};
  }
  &.danger {
    border-color: ${(props) => props.theme.button[props.$variant]?.danger?.borderColor};
    color: ${(props) => props.theme.button[props.$variant]?.danger?.color};
    background-color: ${(props) => props.theme.button[props.$variant]?.danger?.background};
  }
  &.selected {
    border-color: ${(props) => props.theme.button[props.$variant]?.selected?.borderColor};
    color: ${(props) => props.theme.button[props.$variant]?.selected?.color};
    background-color: ${(props) => props.theme.button[props.$variant]?.selected?.background};
  }
  &:disabled {
    border-color: ${(props) => props.theme.button[props.$variant]?.disabled?.borderColor};
    color: ${(props) => props.theme.button[props.$variant]?.disabled?.color};
    background-color: ${(props) => props.theme.button[props.$variant]?.disabled?.background};
  }
  &:hover {
    text-decoration: underline;
  }
`;
